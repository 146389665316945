import React, { useEffect, useState } from 'react';
import './Home.css';

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);

    handleResize(); // Run on initial render
    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  return (
    <div className="home-container">
      <video autoPlay muted loop playsInline className="background-video">
        {/* Προσθήκη WebM και MP4 sources */}
        <source
          src={isMobile ? '/assets/video-mobile.webm' : '/assets/video-desktop.webm'}
          type="video/webm"
        />
        <source
          src={isMobile ? '/assets/video-mobile.mp4' : '/assets/video-desktop.mp4'}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Home;
